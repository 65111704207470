<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Empresa</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="empresaRequest.status"
                        label="Ativar"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            
            <v-row style="height: 100px">
                <v-col cols="4"
                    lg="4"
                    md="4"
                    sm="4"
                >
                    <label>CNPJ</label>
                    <v-text-field
                        v-model="empresaRequest.cnpj" 
                        :rules=[validations.required,validations.validCnpj]
                        v-mask="'##.###.###/####-##'"
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="8"
                    lg="8"
                    md="8"
                    sm="8"
                >
                    <label>Razão Social</label>
                    <v-text-field
                        v-model="empresaRequest.razaoSocial" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="empresaRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required, validCnpj} from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            empresaRequest: {
                id: 0,
                cnpj: "",
                razaoSocial: ""
            },

            loading: false,
            
            validations: {
                required: required,
                validCnpj: validCnpj
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("empresaModule/GetById", this.id);

                    if (response.success) {
                        this.empresaRequest = response.result;
                    }

                }
                else {
                    this.empresaRequest.status = 1;
                }
            },

            async cancel() {
                this.$router.push({ path: "/empresa/empresaList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Aviso!", "Há inconsistências no formulário. Por favor revise!");
                    return false;
                }
                else {
                        this.loading = true;
                        this.showLoading();

                        this.empresaRequest.id = this.id;

                        const result = await this.$store.dispatch("empresaModule/CreateUpdate", this.empresaRequest);

                        if (result.success === true) {
                            this.showToast("success", "Sucesso!", result.message);
                            this.$router.push({ path: "/empresa/empresaList" });
                        }
                        else {
                            this.showToast("error", "Aviso!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();

            this.hideLoading();
        }
    })
</script>